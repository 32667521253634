<template>
  <div class="ui-calendar-schedule">
    <div
      v-for="(day, dk) in days"
      :key="dk"
      class="day-row"
      :class="day.classNames"
      @click="$emit('click-day', day.date)"
    >
      <div class="day-date">
        <span class="day">{{ day.date.getDate() }}</span>
        <span class="month">{{ $date(day.date, "MMM") }}</span>
        <span class="weekday">{{ $date(day.date, "EEE") }}</span>
      </div>

      <div class="day-contents">
        <div
          v-for="(event, i) in day.events"
          :key="i"
          @click="$emit('click-event', event)"
          :class="['day-event', 'ui-calendar-event', event.className]"
          :style="[{backgroundColor: event.color}, event.style]"
        >
          <div class="time">
            <span class="start">{{ $date(event.dateStart, 'time') }}</span>
            -
            <span class="end">{{ $date(event.dateEnd, 'time') }}</span>
          </div>

          <div class="description">{{ event.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';

export default {
  name: 'ui-calendar-schedule',
  mixins: [useI18n],

  props: {
    events: {
      type: Array,
      required: false,
      default: () => [],
    },

    date: {
      type: Date,
      required: false,
      default: () => new Date(),
    },

    numDaysBefore: {
      type: Number,
      required: false,
      default: 5,
    },

    numDaysAfter: {
      type: Number,
      required: false,
      default: 30,
    },
  },

  computed: {
    days() {
      let retval = [];

      let startDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() - this.numDaysBefore
      );
      let endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + this.numDaysAfter
      );

      let curDate = startDate;
      while (curDate <= endDate) {
        let dayEvents = this.getEventsForDay(curDate);

        let isSelectedDay =
          curDate.getFullYear() === this.date.getFullYear() &&
          curDate.getMonth() === this.date.getMonth() &&
          curDate.getDate() === this.date.getDate();

        let day = {
          date: new Date(curDate.getTime()),
          events: dayEvents,
          classNames: {
            '--selected': isSelectedDay,
            '--today': this.isToday(curDate),
            '--previous': curDate < this.today,
            '--empty': dayEvents.length == 0,
          },
        };

        retval.push(day);
        curDate.setDate(curDate.getDate() + 1);
      }

      return retval;
    },

    today() {
      return new Date();
    },
  },

  methods: {
    isToday(date) {
      let today = new Date();

      return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      );
    },

    // focusWindow() {
    //   let elem = this.$el.querySelector(".--selected");
    //   if (elem) {
    //     elem.scrollIntoView();
    //   }
    // },

    getEventsForDay(date) {
      return this.events.filter((event) => {
        let eventDate = event.dateStart;

        return (
          date.getFullYear() == eventDate.getFullYear() &&
          date.getMonth() == eventDate.getMonth() &&
          date.getDate() == eventDate.getDate()
        );
      });
    },
  },

  // mounted() {
  //   this.focusWindow();
  // }
};
</script>

<style lang="scss">
.ui-calendar-schedule {
  .day-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;

    background-color: #fff;
    border-bottom: 1px solid #ddd;

    .day-date {
      cursor: pointer;

      display: flex;
      align-items: center;

      padding: 10px;
      width: 128px;

      font-size: 0.8em;

      color: #666;

      .day {
        width: 32px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.3em;
        margin-right: 0.5em;
        border-radius: 100%;

        transition: 0.2s all ease;

        &:hover {
          background-color: #e0e0e0;
        }
      }

      .month,
      .weekday {
        padding: 3px;
      }
    }

    .day-contents {
      flex: 1;
      padding: var(--ui-padding);
    }

    &.--previous {
      opacity: 0.5;
    }

    &.--today {
      .day {
        color: #fff;
        background-color: #0288d1;
      }
    }

    // Ocultar dias vacios
    // &.--empty {
    //     display: none;
    // }
  }

  .day-event {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    padding: var(--ui-padding);
    border-radius: var(--ui-radius);
    transition: 0.2s all ease;

    .time {
      font-size: 0.8em;
      width: 150px;
    }

    .description {
      flex: 1;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
</style>